import styled, { css } from 'styled-components'
import { ZIndex } from '../../../utils/Constants'
import Anchor from '../../atoms/Anchor'

export type Status = 'success' | 'error' | 'info'

export interface StyleProps {
  status?: Status
}

export const Label = styled.label<{ isHidden?: boolean }>`
  display: block;
  font-size: 0.75rem;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  transition: all 0.3s ease-in;
  opacity: ${(props) => (props.isHidden ? 0 : 1)};
`

export const ControlSection = styled.div`
  position: relative;

  ${Anchor} {
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    right: 0;
    margin-right: 10px;
    z-index: ${ZIndex.medium};

    svg {
      width: 24px;
      height: 24px;
      fill: ${(props) => props.theme.colors.platinum80};
    }
  }

  .loading {
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    margin-right: 10px;

    svg {
      width: 40px;
      height: 40px;
      fill: ${(props) => props.theme.colors.red};
    }
  }
`

export const Wrapper = styled.div<StyleProps>`
  ${({ status, theme }) => {
    function checkStatus(defaultColor: string) {
      if (status === 'success') return theme.colors.green
      if (status === 'error') return theme.colors.error

      return defaultColor
    }

    return css`
      width: 100%;

      ${Label} {
        color: ${checkStatus(theme.colors.black)};
      }

      .TextField__help {
        color: ${checkStatus(theme.colors.platinum80)};

        svg {
          fill: ${checkStatus(theme.colors.platinum80)};
        }
      }
    `
  }};
`
