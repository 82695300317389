/* eslint-disable no-useless-escape */
import { AxiosResponse } from 'axios'

export function isValidJSON(input: any, stringify = false) {
  try {
    const result = stringify ? JSON.stringify(input) : JSON.parse(input)

    return result && (stringify ? typeof result === `string` : typeof result === `object`)
  } catch (error) {
    return false
  }
}

export function slugify(text: string) {
  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
}

export function toPercent(n: number | string): string {
  if (typeof n === 'string') return n
  return n.toLocaleString('de-DE', {
    style: 'percent',
    minimumFractionDigits: 1,
  })
}
export function toNumber(n: number | string, isBolivia?: boolean): string {
  if (n === null || n === undefined) {
    return ''
  }
  if (typeof n === 'string') return n
  if (isBolivia) return n.toLocaleString('us')
  return n.toLocaleString('de-DE')
}

export function toPad(n: number): string {
  return n < 10 ? `0${n}` : `${n}`
}

export function flatError<T extends string>(err: AxiosResponse<{ error: string; error_description: string }>) {
  const networkError = {
    code: 'NETWORK_ERROR' as T,
  }

  if (!err) return networkError

  return err.data
    ? {
        code: err.data.error.toUpperCase() as T,
        status: err.status,
        metadata: isValidJSON(err, true) ? (JSON.stringify(err) as string) : '',
      }
    : networkError
}

export function toHash<T>(arr: T[], key?: string): { [K: string]: T }
export function toHash<T>(arr: T[] = [], key = 'id') {
  return arr.reduce((acc, current) => {
    /* @ts-ignore */
    acc[current[key]] = current
    return acc
  }, {})
}

export function capitalize(str: string, firstWordOnly = false) {
  if (!str) return ''
  if (firstWordOnly) {
    return `${str[0].toUpperCase()}${str.slice(1)}`
  }
  const arr = str.split(' ')
  if (arr && arr.length)
    return arr
      .map((word) => {
        const lowerCase = word.toLowerCase()
        if (!lowerCase || !lowerCase.length) return ''
        const firstChar = lowerCase[0].toUpperCase()
        return `${firstChar}${lowerCase.slice(1)}`
      })
      .join(' ')
  return ''
}

export function toMonthName(num: number, zeroIndexed = false) {
  let index = num
  if (!zeroIndexed) index -= 1
  if (index < 0 || index > 11) return ''
  const monthNames = [
    'ENERO',
    'FEBRERO',
    'MARZO',
    'ABRIL',
    'MAYO',
    'JUNIO',
    'JULIO',
    'AGOSTO',
    'SEPTIEMBRE',
    'OCTUBRE',
    'NOVIEMBRE',
    'DICIEMBRE',
  ]
  return monthNames[index]
}

export function truncateString(str: string, n: number) {
  return str.length > n ? `${str.substr(0, n - 1)}...` : str
}

export const getDiscountPercentage = (price: number, originalPrice: number | undefined) => {
  if (!originalPrice) return 0
  return Math.round(((originalPrice - price) * 100) / originalPrice)
}

export const minutesToMilliseconds = (m: number | string) => 60000 * (typeof m === 'string' ? parseFloat(m) : m)

export const toCssPrefix = (cssPrefix: string) => {
  const toPrefix = (className: string) => `${cssPrefix}${className}`
  const toPrefixArray = (classNames: string[]) =>
    classNames
      .filter((name) => name)
      .map(toPrefix)
      .join(' ')

  return { cssPrefix, toPrefix, toPrefixArray }
}

export const hexToRGBA = (hex: string, opacity: number) => {
  const hexValue = hex.replace('#', '')
  const r = parseInt(hexValue.slice(0, 2), 16)
  const g = parseInt(hexValue.slice(2, 4), 16)
  const b = parseInt(hexValue.slice(4, 6), 16)
  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}
