import { ShoppingCartInitialState, DiscountDetails, ShoppingCartState } from './context'
import { Product, ProductCart, ProductPromotion, PromotionType } from '../../types'
import { getStoredMarket, getStoredDistributionCenter, getStoredShippingCost } from '../../utils/store'

type ADD_PRODUCT = { type: 'ADD_PRODUCT'; product: Product; quantity: number; promotion: ProductPromotion | null }
type SUBTRACT_PRODUCT = { type: 'SUBTRACT_PRODUCT'; skuCode: string; quantity?: number }
type SET_PRODUCT_QUANTITY = { type: 'SET_PRODUCT_QUANTITY'; skuCode: string; quantity: number }
type REMOVE_PRODUCT = { type: 'REMOVE_PRODUCT'; skuCode: string }
type REMOVE_PRODUCT_ASYNC = { type: 'REMOVE_PRODUCT_ASYNC'; skuCode: string }
type REMOVE_GIFT_ASYNC = { type: 'REMOVE_GIFT_ASYNC'; sku: string }
type SET_LINE_ITEMS_ID = { type: 'SET_LINE_ITEMS_ID'; skuCode: string; lineItemId: string }
type EMPTY = { type: 'EMPTY' }
type RESET = { type: 'RESET' }
type REPLACE_STATE = { type: 'REPLACE_STATE'; state: ShoppingCartState }
type APPLY_COUPON = {
  type: 'APPLY_COUPON'
  amount: number
  code: string
  isRawDiscount: boolean
  discountDetails: DiscountDetails[] | DiscountDetails
  adjustment?: number
  interactionType?: string
}

type REMOVE_COUPON = { type: 'REMOVE_COUPON' }
type SET_CART_ID = { type: 'SET_CART_ID'; cartId: string }
type SET_ITEM_STOCK = { type: 'SET_ITEM_STOCK'; skuCode: string; stock: number }

export type ShoppingCartActions =
  | ADD_PRODUCT
  | SUBTRACT_PRODUCT
  | SET_PRODUCT_QUANTITY
  | REMOVE_PRODUCT
  | REMOVE_PRODUCT_ASYNC
  | REMOVE_GIFT_ASYNC
  | SET_LINE_ITEMS_ID
  | EMPTY
  | RESET
  | REPLACE_STATE
  | APPLY_COUPON
  | REMOVE_COUPON
  | SET_CART_ID
  | SET_ITEM_STOCK

function reducer(state: ShoppingCartState, action: ShoppingCartActions): ShoppingCartState {
  switch (action.type) {
    case 'ADD_PRODUCT': {
      const productToBeAdded: ProductCart = state.byHash[action.product.skuCode] || {
        ...action.product,
        quantity: 0,
        rawPrice: action.product.rawPrice || action.product.price,
      }

      const newByHash = {
        ...state.byHash,
        [productToBeAdded.skuCode]: {
          ...productToBeAdded,
          quantity: productToBeAdded.quantity + action.quantity,
          promotion: action.promotion,
        },
      }

      const globalRawTotal = Object.values(newByHash).reduce((acc, curr) => acc + curr.rawPrice * curr.quantity, 0)
      const globalTotal = Object.values(newByHash).reduce((acc, curr) => acc + curr.price * curr.quantity, 0)
      const globalTotalDiscounted = globalRawTotal - globalTotal
      const marketSlug = state.marketSlug ?? getStoredMarket()?.slug
      const distributionCenterSlug = state.distributionCenterSlug ?? getStoredDistributionCenter()?.slug
      const shippingCost = state.shippingCost ?? getStoredShippingCost()?.shippingCost
      const freeOver = state.freeOver ?? getStoredShippingCost()?.freeOver

      return {
        ...state,
        byHash: newByHash,
        globalRawTotal,
        globalTotal,
        globalQuantity: Object.values(newByHash).reduce((acc, curr) => acc + curr.quantity, 0),
        globalTotalDiscounted,
        marketSlug,
        distributionCenterSlug,
        shippingCost,
        freeOver,
      }
    }

    case 'SUBTRACT_PRODUCT': {
      const { [action.skuCode]: productToBeRemoved, ...restProducts } = state.byHash

      if (!productToBeRemoved) return state

      const newByHash =
        productToBeRemoved.quantity === 1
          ? restProducts
          : {
              ...state.byHash,
              [action.skuCode]: {
                ...productToBeRemoved,
                quantity: productToBeRemoved.quantity - (action.quantity ?? 1),
              },
            }

      const globalRawTotal = Object.values(newByHash).reduce((acc, curr) => acc + curr.rawPrice * curr.quantity, 0)
      const globalTotal = Object.values(newByHash).reduce((acc, curr) => acc + curr.price * curr.quantity, 0)
      const globalTotalDiscounted = globalRawTotal - globalTotal
      const marketSlug = state.marketSlug ?? getStoredMarket()?.slug
      const distributionCenterSlug = state.distributionCenterSlug ?? getStoredDistributionCenter()?.slug

      return {
        ...state,
        byHash: newByHash,
        globalRawTotal,
        globalTotal,
        globalQuantity: Object.values(newByHash).reduce((acc, curr) => acc + curr.quantity, 0),
        globalTotalDiscounted,
        marketSlug,
        distributionCenterSlug,
      }
    }

    case 'SET_PRODUCT_QUANTITY': {
      const { [action.skuCode]: productToBeUpdated, ...restProducts } = state.byHash

      if (!productToBeUpdated) return state

      const newByHash =
        action.quantity === 0
          ? restProducts
          : { ...state.byHash, [action.skuCode]: { ...productToBeUpdated, quantity: action.quantity } }

      const globalRawTotal = Object.values(newByHash).reduce((acc, curr) => acc + curr.rawPrice * curr.quantity, 0)
      const globalTotal = Object.values(newByHash).reduce((acc, curr) => acc + curr.price * curr.quantity, 0)
      const globalTotalDiscounted = globalRawTotal - globalTotal
      const marketSlug = state.marketSlug ?? getStoredMarket()?.slug
      const distributionCenterSlug = state.distributionCenterSlug ?? getStoredDistributionCenter()?.slug

      return {
        ...state,
        byHash: newByHash,
        globalRawTotal,
        globalTotal,
        globalQuantity: Object.values(newByHash).reduce((acc, curr) => acc + curr.quantity, 0),
        globalTotalDiscounted,
        marketSlug,
        distributionCenterSlug,
      }
    }

    case 'REMOVE_PRODUCT':
    case 'REMOVE_PRODUCT_ASYNC': {
      const { [action.skuCode]: productToBeRemoved, ...restProducts } = state.byHash

      if (!productToBeRemoved) return state

      const globalRawTotal = Object.values(restProducts).reduce((acc, curr) => acc + curr.rawPrice * curr.quantity, 0)
      const globalTotal = Object.values(restProducts).reduce((acc, curr) => acc + curr.price * curr.quantity, 0)
      const globalTotalDiscounted = globalRawTotal - globalTotal
      const marketSlug = state.marketSlug ?? getStoredMarket()?.slug
      const distributionCenterSlug = state.distributionCenterSlug ?? getStoredDistributionCenter()?.slug

      return {
        ...state,
        byHash: restProducts,
        globalRawTotal,
        globalTotal,
        globalQuantity: Object.values(restProducts).reduce((acc, curr) => acc + curr.quantity, 0),
        globalTotalDiscounted,
        marketSlug,
        distributionCenterSlug,
      }
    }

    case 'REMOVE_GIFT_ASYNC': {
      const lineItem = state.giftLineItems?.find((item) => item.sku === action.sku)

      if (!lineItem) return state

      const giftLineItems = state.giftLineItems?.filter((item) => item.sku !== action.sku)

      return {
        ...state,
        giftLineItems,
      }
    }

    case 'SET_LINE_ITEMS_ID': {
      const productToBeUpdated = state.byHash[action.skuCode]

      if (!productToBeUpdated) return state

      return {
        ...state,
        byHash: {
          ...state.byHash,
          [productToBeUpdated.skuCode]: { ...productToBeUpdated, lineItemId: action.lineItemId },
        },
      }
    }

    case 'APPLY_COUPON': {
      return {
        ...state,
        globalCouponDiscounted: action.amount,
        totalDiscount: action.amount,
        couponCode: action.code,
        isRawDiscount: action.isRawDiscount,
        discountDetails: Array.isArray(action.discountDetails)
          ? action.discountDetails
          : [...(state.discountDetails ? state.discountDetails : []), action.discountDetails],
        adjustment: action.adjustment,
        cuponInteractionType: action.interactionType,
      }
    }

    case 'REMOVE_COUPON': {
      const discountDetails = state.discountDetails?.filter(
        (discount) =>
          discount.promotion.type === PromotionType.PRICE_REDUCTION ||
          discount.promotion.type === PromotionType.MXN ||
          discount.promotion.type === PromotionType.X_UNIT ||
          discount.promotion.type === PromotionType.A_DISCOUNTS_B ||
          discount.promotion.type === PromotionType.A_PERCENTAGE_DISCOUNTS_B,
      )
      const totalDiscount = discountDetails?.reduce((total, discount) => total + discount.discountAmount, 0)
      return {
        ...state,
        globalCouponDiscounted: 0,
        couponCode: undefined,
        cuponInteractionType: undefined,
        isRawDiscount: false,
        discountDetails,
        totalDiscount,
      }
    }

    case 'REPLACE_STATE': {
      return action.state
    }

    case 'EMPTY':
    case 'RESET':
      return {
        ...ShoppingCartInitialState,
        freeOver: state.freeOver,
        shippingCost: state.shippingCost,
        cartId: state.cartId,
        maxCartLineProductQuantity: state.maxCartLineProductQuantity,
        maxCartLineBundleQuantity: state.maxCartLineBundleQuantity,
      }

    case 'SET_CART_ID':
      return { ...state, cartId: action.cartId }

    case 'SET_ITEM_STOCK': {
      const productToBeUpdated = state.byHash[action.skuCode]
      if (!productToBeUpdated) return state
      return {
        ...state,
        byHash: {
          ...state.byHash,
          [productToBeUpdated.skuCode]: { ...productToBeUpdated, stock: action.stock },
        },
      }
    }

    default:
      throw new Error(`Unhandled action type: ${action}`)
  }
}

export default reducer
