import firebase from 'firebase/app'
import config from '../config'
import { ShoppingCart } from '../context/ShoppingCart/context'
import { ShoppingCartContext } from '../context/ShoppingCart'
import { useFirebase } from '../context/Firebase'
import { log } from '../utils/log'
import { getStoredMarket, getStoredShippingCost } from '../..'
import { getStoredDistributionCenter } from '../utils/store'

export const CARTS_COLLECTION = config.FIREBASE_CARTS_COLLECTION

export interface FirestoreDate {
  seconds: number
  nanoseconds: number
}

export interface FirestoreShoppingCart extends ShoppingCart {
  id: string
  updatedAt: FirestoreDate
  createdAt: FirestoreDate
}

type ShoppingCartState = ShoppingCartContext['state']

export const useFirestore = () => {
  const { getFirebase } = useFirebase()
  const objectToShoppingCart = (object: any): ShoppingCart => ({
    ...{
      byHash: object?.byHash ?? ({} as ShoppingCartState['byHash']),
      globalQuantity: object?.globalQuantity ?? 0,
      globalRawTotal: object?.globalRawTotal ?? 0,
      globalTotal: object?.globalTotal ?? 0,
      globalTotalDiscounted: object?.globalTotalDiscounted ?? 0,
      globalCouponDiscounted: object?.globalCouponDiscounted ?? 0,
      isRawDiscount: object?.isRawDiscount ?? false,
      promotions: object?.promotions ?? {},
      discountDetails: object?.discountDetails ?? [],
      totalDiscount: object?.totalDiscount ?? 0,
      promotionsUpdatedAt: object?.promotionsUpdatedAt ?? 0,
      globalTotalPromotion: object.globalTotalPromotion ?? 0,
      giftLineItems: object?.giftLineItems ?? [],
      cuponInteractionType: object?.cuponInteractionType ?? undefined,
      isOpenConfirmationAlert: object?.isOpenConfirmationAlert,
      shippingCost: object?.shippingCost ?? 0,
      freeOver: object?.freeOver ?? 0,
    },
    ...(object.orderId ? { orderId: object.orderId } : {}),
    ...(object.couponCode ? { couponCode: object.couponCode } : {}),
  })

  const stateToShoppingCart = (cartState: ShoppingCartState): ShoppingCart => {
    const { cartId, ...firestoreCart } = cartState

    return Object.entries({ ...firestoreCart })
      .filter(([, val]) => val !== undefined)
      .reduce<ShoppingCart>((fsCart, [key, val]) => ({ ...fsCart, [key]: val }), {} as ShoppingCart)
  }

  const fsShoppingCartSubscription = (fsCartId: string, onSnapshot: (doc: any) => void) => {
    const instance = getFirebase()
    if (!instance) return
    const unsuscribe = instance.firestore().collection(CARTS_COLLECTION).doc(fsCartId).onSnapshot(onSnapshot)
    return unsuscribe
  }

  const createFsShoppingCart = (cartState: ShoppingCartState): Promise<string> =>
    new Promise((resolve, reject) => {
      const instance = getFirebase()
      if (!instance) {
        resolve('')
        return
      }
      instance
        .firestore()
        .collection(CARTS_COLLECTION)
        .add({
          ...stateToShoppingCart(cartState),
          createdAt: new Date(),
          updatedAt: new Date(),
          marketSlug: getStoredMarket()?.slug ?? '',
          distributionCenterSlug: getStoredDistributionCenter()?.slug ?? '',
          shippingCost: getStoredShippingCost()?.shippingCost ?? 0,
          freeOver: getStoredShippingCost()?.freeOver ?? 0,
        })
        .then((cart: any) => resolve(cart.id))
        .catch(reject)
    })

  const pushCartStateToFirestore = (fsCartId: string, cartState: ShoppingCartState) => {
    const instance = getFirebase()
    if (!instance) return
    instance
      .firestore()
      .collection(CARTS_COLLECTION)
      .doc(fsCartId)
      .update({ ...stateToShoppingCart(cartState), updatedAt: new Date() })
  }

  const fetchFsShoppingCart = (fsCartId: string) => {
    const instance = getFirebase()
    if (!instance) return
    return instance.firestore().collection(CARTS_COLLECTION).doc(fsCartId).get()
  }

  const placeFsShoppingCart = (fsCartId: string) => {
    const instance = getFirebase()
    if (!instance) return
    return instance
      .firestore()
      .collection(CARTS_COLLECTION)
      .doc(fsCartId)
      .update({ status: 'placed', updatedAt: new Date() })
  }

  const queryCartsByIdList = (ids: string[]) => {
    return new Promise<FirestoreShoppingCart[]>((resolve, reject) => {
      try {
        const instance = getFirebase()
        if (!instance) {
          resolve([])
          return
        }
        instance
          .firestore()
          .collection(CARTS_COLLECTION)
          .where(firebase.firestore.FieldPath.documentId(), 'in', ids.length > 10 ? ids.slice(0, 9) : ids)
          .get()
          .then((querySnapshot: any) => {
            const data: FirestoreShoppingCart[] = []
            querySnapshot.forEach((doc: any) => {
              data.push({ ...doc.data(), id: doc.id })
            })
            resolve(data)
          })
          .catch(reject)
      } catch (e) {
        log.error(e)
        reject(e)
      }
    })
  }

  const getLastValidActiveCartByIdList = async (ids: string[]) => {
    if (!ids.length) return null
    const data = await queryCartsByIdList(ids)
    const sorted = data
      .filter((cart) => cart.globalQuantity > 0)
      .sort((a, b) =>
        a.updatedAt.seconds === b.updatedAt.seconds
          ? b.updatedAt.nanoseconds - a.updatedAt.nanoseconds
          : b.updatedAt.seconds - a.updatedAt.seconds,
      )
    return sorted.length ? sorted[0] : null
  }

  return {
    createFsShoppingCart,
    objectToShoppingCart,
    fsShoppingCartSubscription,
    pushCartStateToFirestore,
    fetchFsShoppingCart,
    placeFsShoppingCart,
    queryCartsByIdList,
    getLastValidActiveCartByIdList,
  }
}
