import React, { ReactNode } from 'react'
import { DefaultTheme } from '@ecommerce/shared'
import { Wrapper } from './AlertMessage.styled'
import { Icon, IconProps } from '../Icon/Icon'

type Props = {
  type: 'success' | 'info' | 'trash' | 'error' | 'advice'
  className?: string
  children: ReactNode
}
const getNotificationStyle = (
  type: string,
): {
  icon: IconProps['iconId']
  color: keyof DefaultTheme['colors']
  backgroundColor: keyof DefaultTheme['colors']
} => {
  switch (type) {
    case 'info':
      return { icon: 'info_outline', color: 'platinum60', backgroundColor: 'grey20' }
    case 'error':
      return { icon: 'info_outline', color: 'error', backgroundColor: 'red20' }
    case 'advice':
      return { icon: 'info_outline', color: 'skyBlue', backgroundColor: 'skyBlue40' }
    default:
      return { icon: 'info_outline', color: 'platinum60', backgroundColor: 'grey20' }
  }
}
const AlertMessage = ({ type, className, children }: Props) => {
  const { icon, color, backgroundColor } = getNotificationStyle(type)
  return (
    <Wrapper className={className} color={color} backgroundColor={backgroundColor}>
      <Icon className="icon" iconId={icon} size={16} fillColor={color} />
      <p className="message">{children}</p>
    </Wrapper>
  )
}

export default AlertMessage
