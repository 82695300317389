import styled from 'styled-components'
import React, { useState } from 'react'

const HelpSection = styled.div<{ isHidden?: boolean }>`
  display: flex;
  font-size: 0.75rem;
  margin-top: 5px;

  svg {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    font-size: 0.6875rem;
    text-align: center;
    border-radius: 60%;
    margin-right: 0.3125rem;
    fill: ${(props) => props.theme.colors.platinum80};
  }
`

const InfoFillIcon = () => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <title>info_fill</title>
    <path d="M16 29.333c-7.364 0-13.333-5.97-13.333-13.333s5.97-13.333 13.333-13.333c7.364 0 13.333 5.97 13.333 13.333s-5.97 13.333-13.333 13.333zM14.667 14.667v8h2.667v-8h-2.667zM16 12c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333z" />
  </svg>
)

const HelpMessage = styled.span`
  width: 90%;
`

interface Props {
  isHidden?: boolean
  message?: string
  className?: string
  icon?: React.ReactNode
}

export default function InfoMessage(props: Props) {
  const { isHidden, message = '', className, icon } = props

  if (isHidden) return null

  return (
    <HelpSection className={className}>
      {icon || <InfoFillIcon />}
      <HelpMessage>{message}</HelpMessage>
    </HelpSection>
  )
}
