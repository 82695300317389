import { AxiosPromise } from 'axios'
import fetch from './fetch'
import config from '../../config'
import { saveState } from '../../utils/localStorage'

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

export interface ResponseAuth {
  access_token: string
  token_type: 'bearer'
  expires_in: number
  scope: string
  created_at: number
}

export interface ResponseSignIn extends ResponseAuth {
  refresh_token: string
  owner_id: string
  owner_type: string
}

export function signIn(params: { username: string; password: string }): AxiosPromise<ResponseSignIn> {
  const data = {
    ...params,
    grant_type: 'password',
    client_id: config.COMMERCE_LAYER_CLIENT_ID,
  }

  return fetch('/oauth/token', { data, headers, method: 'POST' })
}

export function guestSignIn(scope?: number) {
  const data = {
    grant_type: 'client_credentials',
    client_id: config.COMMERCE_LAYER_CLIENT_ID,
    scope: scope ? `market:${scope}` : undefined,
  }

  return fetch('/oauth/token', { data, headers, method: 'POST' })
}

export function persistCredentials(params: {
  access_token: string
  refresh_token: string
  expires_in: number
  isAnonymousToken?: boolean
}) {
  if (params.expires_in) saveState('accessTokenExpirationTimestamp', Date.now() + params.expires_in * 1000)
  if (params.access_token) saveState(params.isAnonymousToken ? 'anonymousToken' : 'accessToken', params.access_token)
  if (params.refresh_token) saveState('refreshToken', params.refresh_token)
}
