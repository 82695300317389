import { Country, PaymentTypes, PaymentMethodsObject } from '../../types'
import { getChilePaymentUrl, initPaymentBolivia } from './index'
import { Market } from '../../..'
import { OrderStatus } from '../CommerceLayer'

export type PaymentOption = {
  id: number
  name: string
  icons: string[]
}

export type PaymentMethod = {
  name: string
  options: PaymentOption[]
}

export type CreatePaymentResponse = {
  payment_id: string
  payment_url?: string
  qr?: string
}

export type GetPaymentStatusResponse = {
  status: string
  orderId: string
  amount: string
  qrCode?: string
  qrCodeRaw?: string
  shippingDate?: string
  orderNumber: number
  orderStatus: OrderStatus
}

export const formatPaymentMethods = (methods: PaymentMethod[]): PaymentMethodsObject => {
  const formattedMethods: PaymentMethodsObject = {
    types: [],
    options: [],
  }
  const webpayCybersourceRegex = /(Cybersource|Webpay)/g

  const flowTypes = [
    {
      regex: webpayCybersourceRegex,
      labels: ['Tarjeta de débito', 'Tarjeta de crédito'],
    },
    {
      regex: /(Chek)/g,
      labels: ['App Chek Ripley'],
    },
    {
      regex: /(Mach)/g,
      labels: ['Tarjeta MACH'],
    },
  ]
  methods.forEach(({ options, name: methodName }) =>
    options
      .sort((a, b) => a.id - b.id)
      .forEach(({ id, name, icons }) => {
        if (webpayCybersourceRegex.test(methodName)) {
          formattedMethods.types.push(
            {
              label: 'Pago en línea',
              value: PaymentTypes.REDENLACE,
              checked: true,
            },
            {
              label: 'Pago contra entrega',
              value: PaymentTypes.CONTRA_ENTREGA,
            },
          )
        }

        let paymentMethodFound = false

        flowTypes.forEach(({ regex, labels }) => {
          if (regex.test(name) || regex.test(methodName)) {
            paymentMethodFound = true
            formattedMethods.options.push(
              ...labels.map((label) => ({
                value: id,
                label,
                image: icons[0] || undefined,
              })),
            )
          }
        })

        if (methodName === 'API-Market' && options && options[0]?.id) {
          formattedMethods.types[1].QRID = options[0].id
        } else if (!paymentMethodFound) {
          formattedMethods.options.push({ value: id, label: name, image: icons[0] || undefined })
        }
      }),
  )

  return formattedMethods
}

function postForm(url: string, data: Record<string, string>) {
  const form = document.createElement('form')
  document.body.appendChild(form)
  form.method = 'post'
  form.action = url
  Object.entries(data).forEach(([key, value]) => {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = key
    input.value = value
    form.appendChild(input)
  })
  form.submit()
}

export interface RedirectToPaymentGatewayParams {
  countryCode: Country
  orderId: string
  currentCity: Market
  sessionId: string
  beforeRedirectCallback?: () => Promise<void>
  sahcUrl: string // sahc stands for secure acceptance hosted checkout
}

export const payInChile = async (params: RedirectToPaymentGatewayParams) => {
  const paymentUrl = await getChilePaymentUrl(params.orderId)
  if (params.beforeRedirectCallback) await params.beforeRedirectCallback()
  window.location.replace(paymentUrl)
}

export const payInBolivia = async (params: RedirectToPaymentGatewayParams) => {
  const formFields = await initPaymentBolivia(params.orderId, params.sessionId)
  if (params.beforeRedirectCallback) await params.beforeRedirectCallback()
  postForm(params.sahcUrl, formFields)
}
