import { createContext } from 'react'
import { ShoppingCartActions } from './reducer'
import { ByHash, Promotions, PromotionDetail, PromotionType, ProductCart } from '../../types'
import { getStoredMarket, getStoredDistributionCenter, getStoredShippingCost } from '../../utils/store'

export interface LineItemMetadata {
  isBundle: boolean
  recyclable: boolean
  price: number
  originalPrice: number
}

// Data strictly describing shopping cart
export interface ShoppingCart {
  byHash: ByHash
  globalQuantity: number
  globalRawTotal: number
  globalTotal: number
  globalTotalDiscounted: number
  couponCode?: string
  discountDetails?: DiscountDetails[]
  totalDiscount?: number
  globalCouponDiscounted: number
  isRawDiscount?: boolean
  adjustment?: number
  hasRawXunitDiscounts?: boolean
  promotions?: Promotions
  promotionsUpdatedAt?: number
  globalTotalPromotion?: number
  giftLineItems?: PromotionDetail[]
  totalGifts?: number
  cuponInteractionType?: string
  orderId?: string
  shippingCost?: number
  freeOver?: number
}

// Metadata for the shopping cart
export interface ShoppingCartState extends ShoppingCart {
  cartId: string
  orderId?: string
  maxCartLineProductQuantity: number
  maxCartLineBundleQuantity: number
  promotionCouponsWhitelist: string[]
  marketSlug?: string
  distributionCenterSlug?: string
}

export enum PromotionInteractionType {
  CUMULATIVE = 'cumulative',
  NON_CUMULATIVE = 'non_cumulative',
  UNIQUE = 'unique',
}

export type DiscountDetails = {
  sku: string
  isGift?: boolean
  quantity: number
  discountAmount: number
  discountQuantity?: number
  promotion: {
    name: string
    type: PromotionType
    uuid: string
    interactionType: PromotionInteractionType
    isRaw: boolean
    discountLimit?: number
    shoppingCartLabel?: string
    catalogDetail?: string
    activationQuantity?: number
  }
}

export const ShoppingCartInitialState: ShoppingCartState = {
  byHash: {} as ByHash,
  globalQuantity: 0,
  globalTotalDiscounted: 0,
  globalCouponDiscounted: 0,
  couponCode: undefined,
  discountDetails: [],
  totalDiscount: 0,
  globalRawTotal: 0,
  globalTotal: 0,
  shippingCost: getStoredShippingCost()?.shippingCost ? getStoredShippingCost()?.shippingCost : 0,
  freeOver: getStoredShippingCost()?.freeOver ? getStoredShippingCost()?.freeOver : 0,
  cartId: '',
  maxCartLineProductQuantity: 0,
  maxCartLineBundleQuantity: 0,
  promotionCouponsWhitelist: [],
  isRawDiscount: false,
  promotions: {},
  promotionsUpdatedAt: 0,
  globalTotalPromotion: 0,
  marketSlug: getStoredMarket()?.slug,
  distributionCenterSlug: getStoredDistributionCenter()?.slug,
  giftLineItems: [],
  totalGifts: 0,
  cuponInteractionType: '',
  orderId: undefined,
}

interface ShoppingCartS extends ShoppingCartState {
  updateProduct: (
    product: ProductCart,
    productQuantity: number,
    orderId?: string | undefined,
    onUpdateCartState?: (() => void) | undefined,
  ) => Promise<void>
}

export const ShoppingCartContext = createContext<ShoppingCartState>(ShoppingCartInitialState)
ShoppingCartContext.displayName = 'ShoppingCart'

export type ShoppingDispatch = (action: ShoppingCartActions) => void
export const DispatchContext = createContext<ShoppingDispatch | undefined>(undefined)
