import { AxiosResponse } from 'axios'
import { getCommerceLayerCLient } from './fetch'
import { getAnonymousToken } from '../../utils/store'
import { formatProductDetailData } from '../../serviceManagers'
import { CLPrice } from './types'

export const getCommerlayerSku = async (code: string) => {
  const token = getAnonymousToken()
  const fetch = getCommerceLayerCLient({ useAnonymousToken: true })

  const {
    data: { included },
  }: AxiosResponse<{ included: CLPrice[] }> = await fetch(`/api/skus?filter[q][code_eq]=${code}&include=prices`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return formatProductDetailData(included)
}
