/* eslint-disable no-console */
import axios, { AxiosInstance } from 'axios'
import config from '../../config'
import { persistCredentials } from './auth'
import {
  signOutRedirect,
  getStoredMarket,
  getAccessTokenExpirationTimestamp,
  getRefreshToken,
  getAccessToken,
} from '../../..'
import handleNetworkError from '../../utils/networkErrorHandler'
import { getAnonymousToken } from '../../utils/store'
import { timeout } from '../../utils/promises'

export interface GetCommerceLayerClientProps {
  ignoreNetworkErrorInterceptor?: boolean
  useAnonymousToken?: boolean
}

let retryCount = 0

export const rawClient = axios.create({
  baseURL: config.COMMERCE_LAYER_HOST,
  timeout: 90000,
})

export function getCommerceLayerCLient(params?: GetCommerceLayerClientProps) {
  const client: AxiosInstance = axios.create({
    baseURL: config.COMMERCE_LAYER_HOST,
  })

  client.interceptors.request.use(
    (api) => {
      const accessToken = params?.useAnonymousToken ? getAnonymousToken() : getAccessToken()

      try {
        // eslint-disable-next-line no-param-reassign
        api.headers.Authorization = `Bearer ${accessToken}`

        return api
      } catch (err) {
        return Promise.reject(err)
      }
    },
    (err) => {
      return Promise.reject(err)
    },
  )

  const renewCredentials = async (refreshToken?: string): Promise<{ access_token: string; refresh_token: string }> => {
    const city = getStoredMarket()
    const payload = {
      ...(refreshToken
        ? {
            grant_type: 'refresh_token',
            refreshToken,
          }
        : {
            grant_type: 'client_credentials',
          }),
      client_id: config.COMMERCE_LAYER_CLIENT_ID,
      scope: `market:all`,
    }

    console.log(`Renew credentials: `, payload, city, { refreshToken })

    const fetch = axios({
      method: 'POST',
      baseURL: config.COMMERCE_LAYER_HOST,
      url: '/oauth/token',
      data: payload,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    try {
      const { data } = await fetch
      persistCredentials({ ...data, isAnonymousToken: params?.useAnonymousToken })
      return data
    } catch (err) {
      console.log(err?.message, { req: err?.request, res: err?.response })

      if (!refreshToken) {
        signOutRedirect()
        throw err
      } else return renewCredentials()
    }
  }

  interface Credentials {
    access_token: string
  }

  const retryRequest = (
    err: { response: { config: { headers: { Authorization: string } } } },
    credentials: Credentials,
  ) => {
    const { config: options } = err.response
    options.headers.Authorization = `Bearer ${credentials.access_token}`

    return new Promise((resolve) => {
      axios
        .request(options)
        .then(resolve)
        .catch((e) => {
          if (e.response.status === 401 && retryCount >= 3) {
            signOutRedirect()
          }

          Promise.resolve(client(err.response.config))
        })
    })
  }

  if (!params || !params.ignoreNetworkErrorInterceptor) {
    client.interceptors.response.use(
      (response) => response,
      async (err) => {
        if (!err.response) handleNetworkError()

        const accessTokenExpirationTimestamp = getAccessTokenExpirationTimestamp()
        const tokenExpired = !accessTokenExpirationTimestamp || Date.now() > accessTokenExpirationTimestamp

        if ((tokenExpired || err.response.status === 401) && retryCount <= 2) {
          retryCount += 1

          await timeout(500)

          const refreshToken = getRefreshToken()
          try {
            const credentials = await renewCredentials(refreshToken)
            return retryRequest(err, credentials)
          } catch (e) {
            console.log('Error renewing credentials or retrying request')
          }
        }

        return Promise.reject(err)
      },
    )
  }

  return client
}

export const headers = {
  Accept: 'application/vnd.api+json',
  'Content-Type': 'application/vnd.api+json',
}

export default getCommerceLayerCLient()
