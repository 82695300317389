import React, { InputHTMLAttributes, Ref, useState } from 'react'
import styled from 'styled-components'
import { Status, Wrapper, ControlSection, Label } from './Styled'
import { baseInput } from '../../../styles/baseForms'
import Anchor from '../../atoms/Anchor'
import InfoMessage from '../../atoms/InfoMessage'
import SuccessIcon from '../../atoms/Icons/SuccessIcon'
import LoadingIcon from '../../atoms/Icons/LoadingIcon'
import EyesOpenIcon from '../../atoms/Icons/EyesOpenIcon'
import EyesCloseIcon from '../../atoms/Icons/EyesCloseIcon'

const InputStyled = styled.input<{ status: Status | undefined }>`
  ${({ status }) => {
    return baseInput(status)
  }}
`

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  helpMessage?: string
  errorMessage?: string
  successMessage?: string
  loading?: boolean
  className?: string
  status?: Status
}

const TextField = React.forwardRef((props: TextFieldProps, ref: Ref<HTMLInputElement>) => {
  const {
    label,
    placeholder,
    name,
    className,
    errorMessage,
    helpMessage,
    successMessage,
    status,
    type,
    loading,
    ...inputProps
  } = props
  const [isFocus, setIsFocus] = useState(false)

  const formattedType = type === 'password' ? 'text' : type
  const [showText, setShowText] = useState(type !== 'password')

  const getMessage = () => {
    if (status === 'error') return errorMessage
    if (status === 'success') return successMessage
    return helpMessage
  }

  return (
    <Wrapper className={`${className} status--${status}`} status={status}>
      <Label className="Select__label" htmlFor={name}>
        {label}
      </Label>
      <ControlSection>
        <InputStyled
          {...inputProps}
          data-test={`input-${name}`}
          ref={ref}
          type={showText ? formattedType : type}
          placeholder={placeholder || (isFocus ? '' : label)}
          onFocus={() => setIsFocus(true)}
          onBlur={(e) => {
            if (inputProps.onBlur) {
              inputProps.onBlur(e)
            }
            setIsFocus(false)
          }}
          name={name}
          status={status}
          className="TextFieldComponent__Base"
        />
        {type === 'password' && (
          <Anchor
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              setShowText(!showText)
            }}
          >
            {showText ? <EyesOpenIcon /> : <EyesCloseIcon />}
          </Anchor>
        )}
        {loading && (
          <div className="loading">
            <LoadingIcon />
          </div>
        )}
      </ControlSection>
      <InfoMessage
        className={`TextField__help status--${status}`}
        isHidden={!(errorMessage || helpMessage || successMessage)}
        message={getMessage()}
        icon={status === 'success' ? <SuccessIcon /> : null}
      />
    </Wrapper>
  )
})

export default TextField
